<template>
  <div>
    <div class="innerbanner_area" style="background-image:url(assets/img/theme1/innerbanner.jpg)"></div>
    <InforStrip :count="searchResultCount" :destination="destinationCityName" :date="dateString"/>

    <SearchPopup />

    <div class="my-5 sportspage_area" :class="{'px-4': device==='desktop'}">
      <div class="col-lg-12 col-12 filter-cont package-organize" v-if="isLoading && !timerEnd">
        <h3 class="text-center">{{ $t("search-result.just-another-moment") }}</h3>
        <div class="content text-center">
          <h3>{{ $t("search-result.domestic-search.search-loading-text2", {destinationName: destinationCityName})}}</h3>
        </div>
        <div class="circle" style="display: flex; justify-content: center; margin : auto">{{countDown}}</div>
        <h3 class="text-center">{{ $t("search-result.seconds") }}</h3>
        <ContentLoading />
      </div>
      <div class="col-lg-12 col-12 filter-cont package-organize" v-else-if="isLoading && timerEnd">
        <div class="content text-center">
          <h3>{{ $t("search-result.domestic-search.search-loading-time-end", {destinationName: destinationCityName})}}</h3>
        </div>
        <ContentLoading />
      </div>
      <div class="container" v-else>
        <div class="row search_topbar">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 col-lg-7 col-md-12 col-12 d-flex align-items-stretch">
                <div class="search_topbartext bg-blue">
                  <strong>{{ $t("search-result.goa") }}: </strong>
                  {{ filteredHotel.length }} {{ $t("search-result.properties-found") }} : {{destinationCityName}}
                </div>
              </div>
              <div class="col-xl-3 col-lg-5 col-md-12 col-12 d-flex align-items-stretch">
                <div class="search_topbarselet bg-blue">
                    <div class="form-group mb-0">
                      <strong>Sort by :</strong>
                      <select-sort-options @updateSort="doSort"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-12 sideBar desktop_form" v-if="device==='desktop'">
            <SearchTabs type="side"/>
            <filter-panel
              :options="filterOptions"
              @change="filterByOption"
            />
          </div>
          <div class="col-lg-9 col-12 filter-cont package-organize" >
            <FilterItem v-for="(item, inx) in filteredHotel" :key="inx" :hotel="item" :itemIndex="inx"/>
          </div>
          <div class="col-lg-3 col-12 sideBar desktop_form d-block" v-if="device==='mobile'">
            <SearchTabs type="side"/>
            <filter-panel
              :options="filterOptions"
              @change="filterByOption"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer page="search" v-if="!hideFooter"/>
  </div>
</template>

<script>
import TemplateSearchResult from './TemplateSearchResult';

export default {
  name: 'SearchResultTheme1',
  mixins: [TemplateSearchResult],
  components: {
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme1'),
    InforStrip: () => import('@/components/searchResult/InforStrip'),
    // SidebarFilterSlide: () => import('@/components/searchResult/atoms/SidebarFilterSlide'),
    FilterPanel: () => import('@/components/searchResult/atoms/FilterPanel'),
    SearchPopup: () => import('@/components/searchResult/SearchPopup'),
    // SearchTab: () => import('@/components/search/SearchTab'),
    FilterItem: () => import('@/components/searchResult/atoms/filterItem/FilterItemTheme1'),
    SelectSortOptions: () => import('@/components/searchResult/atoms/selectSortOptions'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Footer: () => import('@/components/content/footer/FooterTheme1'),
  },
};
</script>

<style scoped>
.innerbanner_area{
    min-height:310px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
  }
  .search_topbar .search_topbartext {
    border-radius: 8px;
    font-size: 25px;
    padding: 9px 19px;
    width: 100%;
    align-items: center;
    direction: ltr;
    justify-content: end;
    text-align: center;
  }
  .bg-blue {
    background-color: #E2F8FC;
  }
  .search_topbar .search_topbarselet {
    width: 100%;
    border-radius: 8px;
    font-size: 20px;
    padding: 10px 15px;
  }
  .search_topbar .search_topbarselet .form-group {
    display: flex;
    width: 100%;
    align-items: center;
  }
  @media(max-width: 1706px) {
    .search_topbar .search_topbarselet .styledSelect {
        max-width: 206px;
        width: 100%;
    }
  }
  @media(max-width: 1399px) {
    .innerbanner_area {min-height: 290px;}
    .search_topbar .search_topbarselet .styledSelect {
        max-width: 196px;
        width: 100%;
    }
    .search_topbar .search_topbarselet .form-group strong {
        font-size: 18px;
        margin-left: 8px;

    }
    .search_topbar .search_topbartext {
        font-size: 18px;
        padding: 9px 15px;

    }

    .search_topbar  .select-selected {
        max-width: 180px;
    }
    .search_topbar  .select-selected {
       height: 47px;
    }
  }
  @media (max-width:991.98px){
    .innerbanner_area {
        min-height: 190px;
    }
    .search_topbar .search_topbartext {
        font-size: 14px;
        padding: 9px 15px;
        border-radius: 8px 8px 0 0;
    }
    .search_topbar .search_topbarselet {
        width: 100%;
        border-radius: 0 0 8px 8px;
        font-size: 14px;
        padding: 5px 15px;
    }
    .search_topbar .tt-select {
        width: 40%;
    }
    .search_topbar .select-selected {
        height: 44px;
        max-width: 267px;
    }
    .search_topbar .search_topbarselet .form-group strong {
        font-size: 15px;
        margin-left: 8px;
    }
  }
  @media (max-width:767.98px){
    .innerbanner_area {
        min-height: 148px;
    }
    .search_topbar .search_topbartext {
        display: inline-table;
    }
  }
  @media (max-width:639.98px){
    .innerbanner_area {
        min-height: 120px;
    }
    .search_topbar .tt-select {
        width: 100%;
        max-width: 260px;
    }
    .search_topbar .search_topbartext {
        text-align: right;
    }
  }
  @media (max-width:479.98px){
    .innerbanner_area {
        min-height: 100px;
    }
    .sportspage_area {
      margin: 0 !important;
    }
    .filter-cont.package-organize {
      margin-top: 0px;
    }
    .search_topbar{
      margin-bottom: 0px;
    }
  }
  @media (max-width:410px){
    .innerbanner_area {
        min-height: 90px;
    }
    .search_topbar .select-selected {
        max-width: 200px;
    }
    .search_topbar .search_topbartext {
        font-size: 15px;
    }
    .search_topbar .select-selected {
        max-width: 190px;
        font-size: 12px;
        height: 40px;
    }
    .search_topbar .select-selected::after {
        top: 10px;
        font-size: 12px;
    }
    .search_topbar .select-items div {
       padding: 6px 15px;
        font-size: 12px;
    }
  }
</style>

<style>
.filter-hotel .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  right: -0.5rem;
}

.dVacation-pack.filter-cont .filterItem:not(:first-child) {
  margin-top: 0px;
}

.modal.show .modal-dialog.noSearchResult {
  display: inline-block;
  top: calc(50vh - 100px);
}

.modal-dialog.noSearchResult .modal-body{
  text-align: center;
}

.content {
  font-size:  20px;
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 36px;
  color: #fff;
  text-align: center;
  background: red;
}

@media (max-width: 479px) {
  .modal.show .modal-dialog.noSearchResult {
    display: flex;
    left: -10px;
    top: unset;
  }

  .content h3 {
    font-size: 20px !important;
  }

  .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    background: red;
  }
}
</style>
